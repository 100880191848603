<template>
	<div class="lineManagement">
		<jy-query ref="checkForm" :model="formInline">
			<jy-query-item label="场站类别:" prop="stationType">
				<el-select v-model="formInline.stationType" placeholder="请选择">
					<el-option v-for="item in lboptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="searchByDate">
				<el-date-picker v-model="formInline.searchByDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary" @click="add">新增</el-button>
                            <el-button type="primary" @click="batchdelete">批量删除</el-button> -->
				<!-- <el-button type="primary" @click="onexport">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<jy-table max-height="595" :data="lists">
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column prop="time" label="运营日期" min-width="100"> </jy-table-column>
			<jy-table-column prop="stationNo" label="场站编号" min-width="100"> </jy-table-column>
			<jy-table-column prop="stationName" label="场站名称" min-width="100"> </jy-table-column>
			<jy-table-column prop="stationType" label="场站类型" min-width="100"> </jy-table-column>
			<jy-table-column prop="parkingSpaceNum" label="车位数" min-width="100"> </jy-table-column>
			<jy-table-column prop="vehicleNum" label="车辆数" min-width="100"> </jy-table-column>
			<jy-table-column prop="parkSpaceNum" label="剩余车位" min-width="100"> </jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<add-dialog ref="dialog" @updateList="getlist"></add-dialog>
	</div>
</template>
<script>
import adddialog from "@/components/pages/admin/statisticalAnalysis/report/parkingLot/checkDetails";
export default {
	data() {
		return {
			formInline: {
				stationType: "",
				searchByDate: ""
			},
			lists: [
				{
					time: "",
					stationNo: "",
					stationName: "",
					parkingSpaceNum: "",
					vehicleNum: "",
					parkSpaceNum: ""
				}
			],
			lboptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "0",
					label: "停车场站"
				},
				{
					value: "1",
					label: "维修场站"
				},
				{
					value: "2",
					label: "充电场站"
				},
				{
					value: "3",
					label: "加油场站"
				}
			],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			//  操作
			operateList: []
		};
	},
	components: {
		"add-dialog": adddialog
	},
	created() {
		this.getDate();
		this.getlist();
		this.setOperateList();
	},
	methods: {
		// 查询默认当天
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			var nowDate = year + "-" + month + "-" + day;
			this.formInline.searchByDate = nowDate;
		},
		setOperateList() {
			let l = [
				{
					name: "查看明细",
					icon: "el-icon-edit-outline",
					fun: this.handleDetail
				}
			];
			this.operateList = l;
		},
		getlist() {
			let url = "/statstationdaily/queryPageList";
			let option = {
				...this.formInline,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.lists = detail.list;
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 导出
		onexport() {
			console.log("导出");
		},

		// 查看明细
		handleDetail(row) {
			this.$refs.dialog.init(row.statStationId);
			console.log("查看明细");
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped="brand"></style>
<style lang="scss"></style>
