import { render, staticRenderFns } from "./checkDetails.vue?vue&type=template&id=0127f36d&scoped=true"
import script from "./checkDetails.vue?vue&type=script&lang=js"
export * from "./checkDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0127f36d",
  null
  
)

export default component.exports